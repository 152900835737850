body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #F4F5F6; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.site-layout-content {
  padding: 50px;
  margin: 0 auto;
  max-width: 740px;
  animation: fadeInUp 0.3s cubic-bezier(.17,.67,0,1.05);
}

@media screen and (max-width: 768px) {
  .site-layout-content {
    margin: 0;
    padding: 50px 20px;
  }
  
}

.instance {
  animation: zoomIn 0.5s cubic-bezier(.17,.67,0,1.05);

}


.site-layout-content > * {
  margin: auto;
}

.site-input-split {
  /* background-color: #fff !important; */
}
.site-input-right {
  border-left-width: 0;
}

.plus {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: zoomIn 0.5s ease-in-out;
}
.plus:hover {
  transform: scale(1.3);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    translate: 0 30px;
  }
  to {
    opacity: 1;
    translate: 0 0;
  }
}




@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


.fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 500ms, transform 500ms;
}